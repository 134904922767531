import {
    breakpointDesktop,
    IconButton,
    InfoCard,
    Heading2Text,
} from '@gnist/design-system';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { Link } from 'react-router';
import { tokens } from '@gnist/themes/tokens.css.js';

const Card = styled(InfoCard)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-color: ${tokens.color.outline};

    @media screen and (min-width: ${breakpointDesktop}) {
        flex-direction: row;
        padding: ${tokens.spacing.xxl};
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const BoxTitle = styled(Heading2Text)`
    margin-bottom: ${tokens.spacing.xxs};
`;

const Description = styled.p`
    margin-bottom: ${tokens.spacing.s};

    @media screen and (min-width: ${breakpointDesktop}) {
        margin-bottom: 0;
    }
`;

export const NewCarBox = ({ className }: { className?: string }) => {
    return (
        <Card className={className} transparent>
            <Content>
                <BoxTitle>{i18next.t('newCarTitle')}</BoxTitle>
                <Description>{i18next.t('newCarInfo')}</Description>
            </Content>
            <IconButton
                icon="arrow_forward"
                label={i18next.t('goToBruktbil')}
                showLabel="left"
                $as={Link}
                to="https://www.bruktbil.no/moller-bil"
            >
                {i18next.t('goToBruktbil')}
            </IconButton>
        </Card>
    );
};
