import { FormButtons } from './FormButtons';
import {
    SelfDeclarationForm,
    SelfDeclarationFormModels,
} from '@/views/self-declaration/components/SelfDeclarationForm';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';
import { useGetUrlParams } from '@/views/valuation-request-view/utils/useGetUrlParams';

export const InfoText = styled.p`
    margin: calc(${tokens.size.l} * -1) 0 ${tokens.size.xl};
`;

interface SelfDeclarationStepProps {
    goToNextStep: () => void;
    selfDeclaration?: SelfDeclarationModel;
    setSelfDeclaration: (selfDeclaration: SelfDeclarationModel) => void;
    setMileage: (mileage: string) => void;
    mileage: string;
    isElectric: boolean;
}

export const SelfDeclarationStep = ({
    goToNextStep,
    selfDeclaration,
    setSelfDeclaration,
    isElectric,
    setMileage,
    mileage,
}: SelfDeclarationStepProps) => {
    const { srcFromUrl } = useGetUrlParams();
    const isFromBilhold =
        srcFromUrl === 'bilhold' || srcFromUrl === 'bilholdPriceEstimate';
    const handleOnSubmit = (data: SelfDeclarationFormModels) => {
        setSelfDeclaration(data as SelfDeclarationModel);
        goToNextStep();
    };

    return (
        <>
            <InfoText>{i18next.t('selfDeclarationCardInfo')}</InfoText>
            <SelfDeclarationForm
                onSubmit={(data) => handleOnSubmit(data)}
                selfDeclaration={selfDeclaration}
                isElectric={isElectric}
                setMileage={setMileage}
                mileage={mileage}
                hideNecessityText
                formBottomContent={
                    <FormButtons
                        extraMargin
                        primaryAction={{
                            textKey: 'next',
                        }}
                        secondaryAction={{
                            textKey: 'cancel',
                            onClick: () =>
                                (window.location.href = isFromBilhold
                                    ? 'https://www.mittbilhold.no/'
                                    : 'https://mollerbil.no/selge-bil/vi-kjoper-bilen-din'),
                        }}
                    />
                }
            />
        </>
    );
};
