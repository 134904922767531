import { useParams, useNavigate } from 'react-router';
import { breakpointDesktop, Heading1Text } from '@gnist/design-system';
import i18next from 'i18next';
import { tokens } from '@gnist/themes/tokens.css.js';
import { styled } from 'styled-components';
import { Page as PageComponent } from '../../components/Page';
import { ErrorContent } from './components/ErrorContent';
import { useGetMyPageValuation } from './queries/useGetMyPageValuation';
import { errorType } from './utils/errorType';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { useGetMyPage } from './queries/useGetMyPage';
import {
    PATH_IMAGES,
    PATH_MY_PAGE,
    PATH_PRICE_ESTIMATE,
} from '@/utils/url/routes';
import { PageHeader } from '@/components/PageHeader';
import { MyPageStep, MyPageStepModel } from './components/MyPageStep';
import { CarStepContent } from './components/CarStepContent';
import { MyPageValuationModel } from '@/types/MyPageValuationModel';
import { ContractStepContent } from './components/ContractStepContent';
import { PriceEstimate } from './components/price-estimate/PriceEstimate';
import { ResponseAlertMessage } from '@/components/ResponseAlertMessage';
import { ValuationStatus } from '@/utils/enums/ValuationStatus';
import { useState } from 'react';
import { NetPromoterScore } from '@/components/net-promoter-score/NetPromoterScore';
import { SvvDownAlertMessage } from '@/components/SvvDownAlertMessage';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';
import { PriceEstimateCustomerResponse } from '@/types/PriceEstimateModel';
import { CarInfo } from '@/views/my-page/components/CarInfo';
import { PriceStepContent } from '@/views/my-page/components/PriceStepContent';
import { numberFormat } from '@/utils/numberFormat';
import { ContactUs } from '@/views/my-page/components/ContactUs';

export const Page = styled(PageComponent)`
    padding: ${tokens.spacing.s} ${tokens.spacing.xxs} ${tokens.spacing.xl};

    > div {
        max-width: 600px;
    }
`;

export const PageTitle = styled(Heading1Text)`
    font-size: ${tokens.typeface.size.xl};
    margin: 0 0 ${tokens.spacing.s};
    line-height: ${tokens.typeface['line-height']};

    @media screen and (min-width: ${breakpointDesktop}) {
        font-size: ${tokens.typeface.size.xxl};
    }
`;

export const MyPageView = () => {
    const [isNPSBannerShowing, setShowNPSBanner] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const {
        data: valuation,
        isPending,
        isError,
        error,
    } = useGetMyPageValuation(id);
    const { data: valuations } = useGetMyPage();
    const hasMultipleValuations = valuations && valuations.length > 1;

    if (isPending) {
        return <LoadingOverlay delayMillis={0} />;
    }

    const steps: (
        valuation: MyPageValuationModel,
    ) => Omit<MyPageStepModel, 'index'>[] = (
        valuation: MyPageValuationModel,
    ) => [
        {
            title: i18next.t('imagesOfCar'),
            content: <CarStepContent valuation={valuation} />,
            link: `${PATH_IMAGES}/${valuation.valuationId}`,
            isActive:
                !valuation.hasImages && !valuation.offer && !valuation.contract,
            isCompleted:
                !!valuation.offer ||
                !!valuation.contract ||
                valuation.hasImages,
        },
        {
            title:
                valuation.offer &&
                valuation.offer.customerResponse ===
                    PriceOfferCustomerResponse.ACCEPTED
                    ? i18next.t('priceOfferTitleWithPrice', {
                          price: `${numberFormat(valuation.offer.purchasePrice)} kr`,
                      })
                    : i18next.t('priceOfferTitle'),
            link: `${PATH_PRICE_ESTIMATE}/${valuation.valuationId}`,
            content: <PriceStepContent offer={valuation.offer} />,
            isActive:
                (valuation.hasImages && !valuation.offer) ||
                (valuation.offer &&
                    valuation.offer.customerResponse !==
                        PriceOfferCustomerResponse.ACCEPTED &&
                    !valuation.contract),
            isCompleted:
                !!valuation.contract ||
                valuation.offer?.customerResponse ===
                    PriceOfferCustomerResponse.ACCEPTED,
        },
        {
            title: i18next.t('assessmentTitle'),
            content: <p>{i18next.t('assessmentInfo')}</p>,
            isActive:
                valuation.offer &&
                valuation.offer.customerResponse ===
                    PriceOfferCustomerResponse.ACCEPTED &&
                !valuation.contract,
            isCompleted: !!valuation.contract,
        },
        {
            title: i18next.t('signContract'),
            content: <ContractStepContent valuation={valuation} />,
            isActive: !!valuation.contract,
            isCompleted: valuation.contract?.status === 'Signed',
        },
    ];

    return (
        <>
            <PageHeader
                titleTextKey="sellMyCar"
                backButtonOnClick={
                    hasMultipleValuations
                        ? () => void navigate(PATH_MY_PAGE)
                        : undefined
                }
                backButtonTextKey="backToOverview"
            />
            <Page>
                {!valuation?.car.brand && <SvvDownAlertMessage />}
                {isError ? (
                    <ErrorContent errorType={errorType(error)} />
                ) : (
                    valuation && (
                        <>
                            {(valuation.status === ValuationStatus.NEW ||
                                valuation.status ===
                                    ValuationStatus.DECLINED) &&
                            valuation.priceEstimate &&
                            valuation.priceEstimate.customerResponse !==
                                PriceEstimateCustomerResponse.INTERESTED ? (
                                <PriceEstimate
                                    priceEstimate={valuation.priceEstimate}
                                    showNPSBanner={() => setShowNPSBanner(true)}
                                />
                            ) : (
                                <>
                                    {isNPSBannerShowing && (
                                        <NetPromoterScore situation="PriceEstimateAccepted" />
                                    )}
                                    <ResponseAlertMessage />
                                    <CarInfo car={valuation.car} />
                                    {steps(valuation).map((step, index) => (
                                        <MyPageStep
                                            step={{ ...step, index }}
                                            key={step.title}
                                        />
                                    ))}
                                    <ContactUs />
                                </>
                            )}
                        </>
                    )
                )}
            </Page>
        </>
    );
};
