export const usePhoneNumberCountryCode = (
    phoneNumber: string | undefined,
    countryCode: string = '+47',
) => {
    const formatPhoneNumber = (
        phoneNumber: string | undefined,
    ): string | undefined => {
        if (!phoneNumber) return undefined;
        return `${countryCode}${phoneNumber}`;
    };

    return formatPhoneNumber(phoneNumber);
};
