import { VEHICLE_APPROVALS } from '@/http/endpoints';
import { post } from '@/http/post';
import { useQuery } from '@tanstack/react-query';
import { RejectionType } from '@/utils/enums/RejectionType';

export interface VehicleApprovalsResponse {
    customerLoginAvailable: boolean;
    rejected: boolean;
    rejectionReason: { type: RejectionType };
}

export const useVehicleApprovals = (
    registrationNumber: string | undefined,
    mileage: string | undefined,
    purchaseHelpDesired: boolean | undefined,
    isProactiveLead = false,
    carIsFetched = false,
) => {
    const checkVehicleApprovals = () =>
        post<VehicleApprovalsResponse>(
            `${VEHICLE_APPROVALS}/${registrationNumber || ''}/?mileage=${
                mileage || ''
            }&purchaseHelpDesired=${
                purchaseHelpDesired?.toString() || ''
            }&isProactiveLead=${isProactiveLead?.toString() || ''}`,
        ).request.then((res) => res.data);

    return useQuery({
        queryKey: ['vehicleApproval', registrationNumber, mileage],
        queryFn: checkVehicleApprovals,
        enabled: !!registrationNumber && !!mileage && carIsFetched,
        refetchOnWindowFocus: false,
        retry: false,
    });
};
