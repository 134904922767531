import { MyPageValuationModel } from '@/types/MyPageValuationModel';
import { ContractInfo } from './ContractInfo';
import i18next from 'i18next';
import { numberFormat } from '@/utils/numberFormat';
import { css, styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';

const Description = styled.p<{ $largePadding: boolean }>`
    ${(props) => css`
        padding-bottom: ${props.$largePadding
            ? tokens.spacing.l
            : tokens.spacing.s};
    `}
`;

export const ContractStepContent = ({
    valuation,
}: {
    valuation: MyPageValuationModel;
}) => {
    const isSigned =
        valuation.contract?.pdfAvailable &&
        valuation.contract.status === 'Signed';

    return (
        <>
            <Description $largePadding={isSigned}>
                {isSigned
                    ? i18next.t('contractSignedDescription')
                    : i18next.t('contractSentDescription', {
                          purchasePrice: numberFormat(
                              valuation.contract?.purchasePrice || '',
                          ),
                          car: `${valuation.car?.brand || ''} ${
                              valuation.car?.model || ''
                          }`,
                          regNo: valuation.car?.registrationNumber,
                      })}
            </Description>
            <ContractInfo />
        </>
    );
};
