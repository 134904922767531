// Security
export const AUTH_SESSION = `auth/session`;
export const AUTH_LOGIN = `auth/login`;
export const AUTH_LOGOUT = `auth/logout`;

// Dealers
export const DEALERS = `dealers`;

// Vehicles
export const VEHICLES = `vehicles/{0}`;

// PostalCodes
export const ZIP_CODES = `postalcodes/{0}`;

// Valuations
export const VALUATIONS = `valuations`;

// Proactive leads
export const PROACTIVE_LEAD = `proactive-leads/{0}`;

// VehicleApprovals
export const VEHICLE_APPROVALS = `vehicle-approvals`;

// Mine
export const MY_VALUATIONS = `my/valuations`;
export const MY_VALUATION = `${MY_VALUATIONS}/{0}`;
export const MY_VALUATION_CONTRACT = `${MY_VALUATION}/contracts`;
export const MY_VALUATION_CONTRACT_DOCUMENT = `${MY_VALUATION}/contract-pdf-document`;
export const MY_VALUATION_CONTRACT_STATUS = `${MY_VALUATION}/contract-status`;
export const MY_VALUATION_SELF_DECLARATION = `${MY_VALUATION}/self-declarations`;
export const MY_VALUATION_PRICE_ESTIMATES = `${MY_VALUATION}/price-estimates`;
export const MY_VALUATION_IMAGES = `${MY_VALUATION}/images`;
export const MY_VALUATION_OFFER = `${MY_VALUATION}/offers/{1}`;
export const MY_VALUATION_EXPECTATIONS = `${MY_VALUATION}/expectations`;
