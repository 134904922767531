import { breakpointTablet, formInputs, FormProvider, useLocalFormState } from "@gnist/design-system"
import { mustBeChecked, noValidation, required, validCellPhoneNumber, validEmail, validZipCode } from "@gnist/design-system/utilities/validation";
import { tokens } from "@gnist/themes/tokens.css.js";
import i18next from "i18next";
import React from "react"
import styled from "styled-components";
import { useGetCity } from "../../utils/useGetCity";
import { FormRadioGroup } from '@/views/my-page-contract/components/FormRadioGroup';
import { FormButtons } from "./FormButtons";
import { CustomerRole } from '@/utils/enums/CustomerRole';
import { DealerModel } from '@/types/DealerModel';
import { useGetUrlParams } from "../../utils/useGetUrlParams";

const SubTitle = styled.h2`
    display: flex;
    font-size: ${tokens.typeface.size.base};
    font-weight: 500;
    margin-top: ${tokens.spacing.xl};
`;

const InputLayout = styled.div`
    display: grid;    
    column-gap: ${tokens.spacing.xl};
    row-gap: ${tokens.spacing.xs};
    margin: ${tokens.spacing.xs} 0;
    @media screen and (min-width: ${breakpointTablet}) {
        grid-template-columns: 1fr 1fr;
    }   
`;

const CompactInputLayout = styled(InputLayout)`
    row-gap: 0;
    margin-bottom: 0;
    margin-top: ${tokens.spacing.m};
`;

const TermsInfoLayout = styled.div`
    margin-top: ${tokens.spacing.xs};

    a {
        color: ${tokens.palette.black};
    }
`;

const Separator = styled.hr`
    margin: ${tokens.spacing.s} 0;
`;

export type ContactInformationStepFormModel = {
    firstName: string;
    lastName: string;
    email: string;
    zipCode: string;
    phoneNumber: string;
    acceptedTerms: boolean;
    isRegisteredOwner: boolean | undefined;
    isPrivatePerson: boolean | undefined;
}

type StepProps = {
    goToPreviousStep: () => void;
    handleOnSubmit: (
        customerData: Omit<
            ContactInformationStepFormModel,
            'isRegisteredOwner' & 'isPrivatePerson'
        > & { customerRole: CustomerRole },
        selectedDealer?: DealerModel,
    ) => void;
}

const { TextField, Checkbox } =
    formInputs<ContactInformationStepFormModel>();

export const ContactInformationStep: React.FC<StepProps> = ({ goToPreviousStep, handleOnSubmit }) => {
    const { phoneNumberFromUrl } = useGetUrlParams();
    const form = useLocalFormState<ContactInformationStepFormModel>({
        firstName: '',
        lastName: '',
        email: '',
        zipCode: '',
        phoneNumber: phoneNumberFromUrl ?? '',
        acceptedTerms: false,
        isRegisteredOwner: undefined,
        isPrivatePerson: undefined,
    }, {
        firstName: noValidation(),
        lastName: noValidation(),
        email: required(validEmail),
        zipCode: required(validZipCode),
        phoneNumber: required(validCellPhoneNumber),
        acceptedTerms: mustBeChecked(i18next.t('requiredField')),
        isRegisteredOwner: required(),
        isPrivatePerson: required(),
    });

    const { city } = useGetCity(form.state.validated.zipCode);


    return <FormProvider
        id="contactInformationStep"
        form={form}
        onSubmit={(customerData) => {
            handleOnSubmit(
                {
                    ...customerData,
                    customerRole: customerData.isRegisteredOwner
                        ? CustomerRole.REGISTERED_OWNER
                        : customerData.isPrivatePerson
                            ? CustomerRole.BEHALF_OF_OWNER
                            : CustomerRole.BEHALF_OF_COMPANY,
                },
                undefined,
            );
        }}
        hideNecessityText>
        <p>{i18next.t('contactInformationLead')}</p>
        <CompactInputLayout>
            <TextField
                field="firstName"
                label={i18next.t('firstName')}
                autoComplete="given-name"
            />
            <TextField
                field="lastName"
                label={i18next.t('lastName')}
                autoComplete="family-name"
            />
            <TextField
                field="zipCode"
                label={i18next.t('zipCode')}
                inputMode="numeric"
                autoComplete="postal-code"
                suffix={city}
            />
            <TextField
                field="email"
                label={i18next.t('email')}
                type="email"
                autoComplete="email"
                inputMode="email"
            />
            <TextField
                field="phoneNumber"
                label={i18next.t('phoneNumber')}
                prefix="+47"
                helperText={i18next.t('phoneHelpText')}
                type="tel"
                inputMode="tel"
                autoComplete="tel"
            />
        </CompactInputLayout>
        <FormRadioGroup
            form={form}
            field="isRegisteredOwner"
            boldGroupLabel
            firstRadioOnChange={() => {
                form.inputProps('isPrivatePerson').setValue(false);
            }}
            secondRadioOnChange={() => {
                if (
                    form.inputProps('isPrivatePerson').value !== undefined
                ) {
                    form.inputProps('isPrivatePerson').setValue(undefined);
                }
            }}
        />
        {form.inputProps('isRegisteredOwner').value === false && (
            <FormRadioGroup
                form={form}
                field="isPrivatePerson"
                boldGroupLabel
                firstRadioLabel="privatePerson"
                secondRadioLabel="company"
            />
        )}
        <SubTitle>{i18next.t('consent')}</SubTitle>
        <TermsInfoLayout>
            <p>
                {i18next.t('termsInfoPart1')}
                <a href="./personvern/" target="_blank" rel="noreferrer">
                    {i18next.t('termsInfoPart2')}
                </a>
                {i18next.t('termsInfoPart3')}
                <a
                    href="https://mollerbil.no/personvern/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {i18next.t('termsInfoPart4')}
                </a>
                .
            </p>
        </TermsInfoLayout>
        <Separator />
        <Checkbox label={i18next.t('termsLabel')} field="acceptedTerms" />
        <FormButtons
            extraMargin
            primaryAction={{
                textKey: 'confirmAndSend',
                icon: 'arrow_forward',
            }}
            secondaryAction={{
                textKey: 'previous',
                onClick: goToPreviousStep,
            }}
        />
    </FormProvider>
}