import { styled } from 'styled-components';
import { PageHeader } from '@/components/PageHeader';
import { Page as PageComponent } from '../../components/Page';
import { useParams, useNavigate } from 'react-router';
import { useGetMyPageValuation } from '../my-page/queries/useGetMyPageValuation';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { useEffect } from 'react';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { errorType } from '../my-page/utils/errorType';
import { ErrorContent } from '../my-page/components/ErrorContent';
import { PriceOffer } from '@/views/my-page/components/PriceOffer';
import { ContactUs } from '@/views/my-page/components/ContactUs';
import { CarInfo } from '@/views/my-page/components/CarInfo';
import { MyPageStep } from '@/views/my-page/components/MyPageStep';
import i18next from 'i18next';
import { MyPageValuationModel } from '@/types/MyPageValuationModel';

export const Page = styled(PageComponent)`
    > div {
        max-width: 600px;
    }
`;

export const Wrapper = styled.div`
    display: grid;
`;

export const PriceEstimateView = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const {
        data: valuation,
        isPending,
        isError,
        error,
    } = useGetMyPageValuation(id);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const priceEstimateStep = (valuation: MyPageValuationModel) => ({
        title: i18next.t('priceOfferTitle'),
        content: <PriceOffer offer={valuation.offer} />,
        isActive: !!valuation.offer,
        isCompleted: false,
    });

    return (
        <>
            {isPending && <LoadingOverlay fixed delayMillis={0} />}
            {id && (
                <PageHeader
                    titleTextKey="priceEstimate"
                    backButtonOnClick={() =>
                        void navigate(`${PATH_MY_PAGE}/${id}`)
                    }
                    backButtonTextKey="toFrontpage"
                />
            )}
            <Page>
                {isError ? (
                    <ErrorContent errorType={errorType(error)} />
                ) : (
                    valuation && (
                        <Wrapper>
                            <CarInfo car={valuation.car} />
                            {valuation?.offer && (
                                <MyPageStep
                                    step={{
                                        ...priceEstimateStep(valuation),
                                        index: 1,
                                    }}
                                />
                            )}
                            <ContactUs />
                        </Wrapper>
                    )
                )}
            </Page>
        </>
    );
};
