import { MyPageValuationModel } from '@/types/MyPageValuationModel';
import { styled } from 'styled-components';
import i18next from 'i18next';
import { tokens } from '@gnist/themes/tokens.css.js';
import { PrimaryButton, breakpointTablet } from '@gnist/design-system';
import { Link } from 'react-router';
import { PATH_IMAGES } from '@/utils/url/routes';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const Description = styled.p`
    margin-bottom: ${tokens.spacing.s};
`;

const ButtonWrapper = styled.div`
    a {
        width: 100%;

        @media screen and (min-width: ${breakpointTablet}) {
            width: fit-content;
        }
    }
`;

export const CarStepContent = ({
    valuation,
}: {
    valuation: MyPageValuationModel;
}) => {
    const imagesIsSent = valuation?.hasImages;
    useEffect(() => {
        if (valuation && !imagesIsSent) {
            datadogRum.addAction('Add images card visible');
        }
    }, [valuation, imagesIsSent]);

    return (
        <div>
            <Description>{i18next.t('carStepInfo')}</Description>
            <ButtonWrapper>
                <PrimaryButton
                    $as={Link}
                    to={`${PATH_IMAGES}/${valuation.valuationId}`}
                >
                    {i18next.t('uploadImagesOfCar')}
                </PrimaryButton>
            </ButtonWrapper>
        </div>
    );
};
