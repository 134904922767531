import {
    AllowedIcons,
    Heading2Text,
    Icon,
    InfoCard,
    PrimaryButton,
} from '@gnist/design-system';
import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';
import parse from 'html-react-parser';
import { Link } from 'react-router';

const Title = styled(Heading2Text)`
    color: ${tokens.color.primary};
    font-size: ${tokens.typeface.size.xl};
`;

const Text = styled.p`
    padding: ${tokens.spacing.xxs} 0 ${tokens.spacing.s};

    b {
        font-weight: ${tokens.typeface.weight.semibold};
    }
`;

interface Props {
    readonly title: string;
    readonly text: string;
    readonly button?: {
        text: string;
        href: string;
        leadingIcon?: AllowedIcons;
    };
}

export const CustomInfoCard = ({ title, text, button }: Props) => {
    return (
        <InfoCard style={{ border: 'none' }}>
            <Title>{title}</Title>
            <Text>{parse(text)}</Text>
            {button && (
                <PrimaryButton $as={Link} to={button.href}>
                    {button.leadingIcon && <Icon icon={button.leadingIcon} />}
                    {button.text}
                </PrimaryButton>
            )}
        </InfoCard>
    );
};
