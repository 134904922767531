import { ProgressBar } from '@/views/my-page-contract/components/ProgressBar';
import { ReactElement, useEffect, useState } from 'react';
import { FormHeadline } from '@/views/my-page-contract/MyPageContract';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { breakpointTablet } from '@gnist/design-system/utilities/layout';
import { RejectionPageContent } from '../RejectedPageContent';
import { DealerModel } from '@/types/DealerModel';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { datadogRum } from '@datadog/browser-rum';
import { AlertMessage } from '../AlertMessage';
import { useGetUrlParams } from '../../utils/useGetUrlParams';
import { RequestModel } from '../../queries/useConfirmRequest';
import { SelfDeclarationStep } from '@/views/valuation-request-view/components/valuation-form/SelfDeclarationStep';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';
import { tokens } from '@gnist/themes/tokens.css.js';
import { CarInfo } from '@/views/valuation-request-view/components/valuation-form/CarInfo';
import { useGetCar } from '@/views/valuation-request-view/utils/useGetCar';
import { useVehicleApprovals } from '@/views/valuation-request-view/queries/useVehicleApprovals';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { InvalidCar } from '@/views/valuation-request-view/components/valuation-form/InvalidCar';
import { ContactInformationStep } from './ContactInformationStep';
import { getCurrentEnvironmentName } from '@/http/utils/getCurrentEnvironment';

enum ValuationFormStep {
    SELF_DECLARATION = 'SELF_DECLARATION',
    CUSTOMER = 'CUSTOMER',
}

interface StepContent {
    title: string;
    component: ReactElement;
}

export const steps: ValuationFormStep[] = [
    ValuationFormStep.SELF_DECLARATION,
    ValuationFormStep.CUSTOMER,
    ValuationFormStep.CUSTOMER,
];

const FormWrapper = styled.div`
    padding: 0;
    position: relative;

    @media screen and (min-width: ${breakpointTablet}) {
        padding: ${tokens.spacing.l} 0;
    }
`;

interface ValuationFormProps {
    dealers: DealerModel[] | undefined;
    handleOnSubmit: (data: RequestModel, selectedDealer?: DealerModel) => void;
    isError: boolean;
    setCarModel: (carModel: string) => void;
}

type CarInfoFromUrlModel = {
    registrationNumber: string;
    mileage: string;
    purchaseHelpDesired: boolean;
};

const initialCarValues: CarInfoFromUrlModel = {
    registrationNumber: '',
    mileage: '',
    purchaseHelpDesired: false,
};

export const ValuationForm = ({
    handleOnSubmit,
    isError,
    setCarModel,
}: ValuationFormProps) => {
    const {
        regNoFromUrl,
        mileageFromUrl,
        purchaseHelpDesiredFromUrl,
        uuidFromUrl,
    } = useGetUrlParams();
    const [car, setCar] = useState<CarInfoFromUrlModel>(initialCarValues);
    const [selfDeclaration, setSelfDeclaration] =
        useState<SelfDeclarationModel>();
    const [activeStep, setActiveStep] = useState(steps[0]);
    const [mileage, setMileage] = useState('');
    const indexOfActiveStep = steps.indexOf(activeStep);
    const goToNextStep = () => setActiveStep(steps[indexOfActiveStep + 1]);
    const goToPreviousStep = () => setActiveStep(steps[indexOfActiveStep - 1]);

    if (!regNoFromUrl) {
        const environment = getCurrentEnvironmentName();
        let url = 'https://mollerbil.no/selge-bil/vi-kjoper-bilen-din';
        if (environment === 'local')
            url =
                '/?registrationNumber=EB11000&mileage=12300&purchaseHelpDesired=false';
        else if (environment !== 'prod')
            url = `https://${environment}.mollerbil.no/selge-bil/vi-kjoper-bilen-din`;

        window.location.href = url;
    }

    const {
        data: fetchedCarData,
        isSuccess: getCarIsSuccess,
        isPending: getCarIsLoading,
        isError: getCarHasError,
    } = useGetCar(regNoFromUrl);

    useEffect(() => {
        if (getCarIsSuccess && fetchedCarData)
            setCarModel(`${fetchedCarData.brand} ${fetchedCarData.model}`);
    }, [fetchedCarData, getCarIsSuccess, setCarModel]);

    const { data: checkedCarData, isLoading: checkCarIsLoading } =
        useVehicleApprovals(
            regNoFromUrl,
            mileage || mileageFromUrl,
            purchaseHelpDesiredFromUrl,
            !!uuidFromUrl,
            getCarIsSuccess,
        );

    useEffect(() => {
        if (!car.registrationNumber) {
            setCar({
                registrationNumber: regNoFromUrl || '',
                mileage: mileageFromUrl || '',
                purchaseHelpDesired: purchaseHelpDesiredFromUrl || false,
            });
        }
    }, [
        car.registrationNumber,
        mileageFromUrl,
        purchaseHelpDesiredFromUrl,
        regNoFromUrl,
    ]);

    useEffect(() => {
        const activeStepActionInfo = () => {
            switch (activeStep) {
                case ValuationFormStep.SELF_DECLARATION:
                    return 'Valuation form selfDeclaration step';
                case ValuationFormStep.CUSTOMER:
                    return 'Valuation form customer step';
                default:
                    return '';
            }
        };

        if (activeStep) {
            datadogRum.addAction(activeStepActionInfo());
        }
    }, [activeStep]);

    const stepContent = (step: ValuationFormStep): StepContent => {
        switch (step) {
            case ValuationFormStep.SELF_DECLARATION:
                return {
                    component: (
                        <SelfDeclarationStep
                            goToNextStep={() => goToNextStep()}
                            selfDeclaration={selfDeclaration}
                            setSelfDeclaration={setSelfDeclaration}
                            isElectric={fetchedCarData?.isElectric || false}
                            mileage={mileage}
                            setMileage={setMileage}
                        />
                    ),
                    title: 'valuationFormSelfDeclarationLabel',
                };
            case ValuationFormStep.CUSTOMER:
                return {
                    component: (
                        <ContactInformationStep
                            goToPreviousStep={() => goToPreviousStep()}
                            handleOnSubmit={(customerData, selectedDealer) =>
                                handleOnSubmit(
                                    {
                                        ...customerData,
                                        registrationNumber:
                                            car.registrationNumber,
                                        purchaseHelpDesired:
                                            car.purchaseHelpDesired,
                                        mileage: mileageFromUrl
                                            ? car.mileage
                                            : mileage,
                                        selfDeclaration,
                                        dealerNumber: '',
                                    },
                                    selectedDealer,
                                )
                            }
                        />
                    ),
                    title: 'valuationFormCustomerLabel',
                };
            default:
                return {
                    component: <></>,
                    title: '',
                };
        }
    };

    const isFirstStep = activeStep === ValuationFormStep.SELF_DECLARATION;

    if (getCarIsLoading || (checkCarIsLoading && !getCarHasError)) {
        return <LoadingOverlay fixed delayMillis={0} />;
    }
    return (
        <FormWrapper>
            {getCarHasError ? (
                <InvalidCar />
            ) : checkedCarData?.rejected ? (
                <RejectionPageContent
                    rejectionType={checkedCarData.rejectionReason.type}
                />
            ) : (
                <>
                    {isFirstStep && <AlertMessage />}
                    <ProgressBar steps={steps} activeStep={activeStep} />
                    {isFirstStep && <CarInfo fetchedCar={fetchedCarData} />}
                    <FormHeadline>
                        {i18next.t(stepContent(activeStep).title)}
                    </FormHeadline>
                    {stepContent(activeStep).component}
                    {isError && <GeneralErrorAlert />}
                </>
            )}
        </FormWrapper>
    );
};
