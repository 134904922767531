import { css, styled } from 'styled-components';
import { Heading1Text, IconButton } from '@gnist/design-system';
import { ReactElement } from 'react';
import i18next from 'i18next';
import { StepStatus } from '@/views/my-page/components/StepStatus';
import { tokens } from '@gnist/themes/tokens.css.js';
import { Link } from 'react-router';

const Wrapper = styled.div<{ $isActive: boolean }>`
    ${(props) => css`
        margin-top: ${tokens.spacing.l};
        padding: 0 ${tokens.spacing.s};
        ${props.$isActive &&
        css`
            background-color: ${tokens.color.surface};
            border: 1px solid #d2c5b4;
            box-shadow: ${tokens.elevation.medium};
            border-radius: ${tokens.radius.card};
            padding: ${tokens.spacing.s};
        `}
    `}
`;

const Content = styled.div`
    position: relative;
    margin: calc(${tokens.spacing.xxs} * -1);
    padding: ${tokens.spacing.xxs};

    > div {
        padding: ${tokens.spacing.s} 0 0;
    }
`;

const StepIndex = styled.p`
    font-weight: 500;
    font-size: ${tokens.typeface.size.s};
    line-height: ${tokens.typeface['line-height']};
    text-transform: uppercase;
`;

const Header = styled.div<{ $isActive: boolean }>`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;
        }

        ${Heading1Text.selector} {
            margin: 0 ${tokens.spacing.xxs} 0 0;
            color: ${tokens.color.primary};
            font-size: ${props.$isActive ? tokens.typeface.size.xxl : '22px'};
            font-weight: ${props.$isActive
                ? tokens.typeface.weight.semibold
                : tokens.typeface.weight.regular};
        }
    `}
`;

export interface MyPageStepModel {
    index: number;
    title: string;
    content: ReactElement;
    isActive: boolean;
    isCompleted: boolean;
    link?: string;
}

export const MyPageStep = ({ step }: { step: MyPageStepModel }) => {
    return (
        <Wrapper $isActive={step.isActive}>
            <StepIndex>
                {i18next.t('stepIndex', { index: step.index + 1 })}
            </StepIndex>
            <Header $isActive={step.isActive}>
                <div>
                    <StepStatus
                        status={
                            step.isCompleted
                                ? 'Completed'
                                : step.isActive
                                  ? 'Active'
                                  : 'NotStarted'
                        }
                    />
                    <Heading1Text>{step.title}</Heading1Text>
                </div>
                {step.isCompleted && step.link && (
                    <IconButton
                        icon="arrow_forward"
                        $as={Link}
                        to={step.link}
                    />
                )}
            </Header>
            {step.isActive && (
                <Content>
                    <div>{step.content}</div>
                </Content>
            )}
        </Wrapper>
    );
};
