export const PATH_VALUATION_REQUEST_CONSUMER = '/';
export const PATH_VALUATION_REQUEST_TRADE_IN = '/innbytte';
export const PATH_VALUATION_REQUEST_COMPANY_CAR = '/firmabil';
export const PATH_MY_PAGE = '/selgbilenmin';
export const PATH_MY_PAGE_ID = `${PATH_MY_PAGE}/:id`;
export const PATH_SELF_DECLARATION = `${PATH_MY_PAGE}/egenerklaering`;
export const PATH_SELF_DECLARATION_ID = `${PATH_SELF_DECLARATION}/:id`;
export const PATH_IMAGES = `${PATH_MY_PAGE}/bilder`;
export const PATH_IMAGES_ID = `${PATH_IMAGES}/:id`;
export const PATH_AUTOMATIC_PRICE_ESTIMATE = `${PATH_MY_PAGE}/automatisk-prisestimat`;
export const PATH_AUTOMATIC_PRICE_ESTIMATE_ID = `${PATH_AUTOMATIC_PRICE_ESTIMATE}/:id`;
export const PATH_PRICE_ESTIMATE = `${PATH_MY_PAGE}/prisestimat`;
export const PATH_PRICE_ESTIMATE_ID = `${PATH_PRICE_ESTIMATE}/:id`;
export const PATH_EXPECTATIONS = `${PATH_MY_PAGE}/tilsvarende`;
export const PATH_EXPECTATIONS_ID = `${PATH_EXPECTATIONS}/:id`;
export const PATH_CONTRACT = `${PATH_MY_PAGE}/kontrakt`;
export const PATH_CONTRACT_ID = `${PATH_CONTRACT}/:id`;
export const PATH_CONTRACT_SIGNED_ID = `${PATH_CONTRACT}-signert/:id`;
export const PATH_CONTRACT_DECLINED_ID = `${PATH_CONTRACT}-avvist/:id`;
export const PATH_DASHBOARD = '/dashboard';
export const PATH_DASHBOARD_VALUATION = '/dashboard/id/:id';
export const PATH_CONSENT = '/personvern';
