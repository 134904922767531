import { Heading2Text, InfoCard } from '@gnist/design-system';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';
import { useGetUrlParams } from '@/views/valuation-request-view/utils/useGetUrlParams';
import { numberFormat } from '@/utils/numberFormat';
import { breakpointTablet } from '@gnist/design-system/utilities/layout';
import { CarPayload } from '@/views/valuation-request-view/utils/useGetCar';

const Card = styled(InfoCard)`
    margin: ${tokens.spacing.xl} 0;
    padding: ${tokens.spacing.xs} ${tokens.spacing.l};

    @media screen and (min-width: ${breakpointTablet}) {
        padding: ${tokens.spacing.l};
    }
`;

const Lead = styled(Heading2Text)`
    text-transform: uppercase;
    font-size: ${tokens.typeface.size.s};
    font-weight: ${tokens.typeface.weight.semibold};
    color: ${tokens.color.secondary};
    margin: 0 0 ${tokens.spacing.xxs};
`;

const Content = styled.div`
    display: flex;
    color: ${tokens.color.primary};
    flex-direction: column;
    gap: ${tokens.spacing.s};

    @media screen and (min-width: ${breakpointTablet}) {
        flex-direction: row;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpointTablet}) {
        &:first-of-type {
            width: 60%;
        }
    }
`;

const Label = styled.dd`
    font-size: ${tokens.typeface.size.m};
    margin: 0;
`;

const Value = styled.dt`
    font-size: ${tokens.typeface.size.l};
    font-weight: ${tokens.typeface.weight.semibold};
`;

interface CarInfoProps {
    fetchedCar: CarPayload | undefined;
}

export const CarInfo = ({ fetchedCar }: CarInfoProps) => {
    const { regNoFromUrl, mileageFromUrl } = useGetUrlParams();

    return (
        <Card style={{ border: 'none' }}>
            <Lead as="h2">{i18next.t('yourCar')}</Lead>
            <Content>
                <Section>
                    <Label>{regNoFromUrl?.toUpperCase()}</Label>
                    {fetchedCar && (
                        <Value>{`${fetchedCar?.brand} ${fetchedCar?.model}`}</Value>
                    )}
                </Section>
                {mileageFromUrl && (
                    <Section>
                        <Label>{i18next.t('mileage')}</Label>
                        <Value>{`${numberFormat(mileageFromUrl)} km`}</Value>
                    </Section>
                )}
            </Content>
        </Card>
    );
};
