import {
    AlertBanner,
    FormProvider,
    PrimaryButton,
    TextButton,
    useLocalFormState,
} from '@gnist/design-system';
import { tokens } from '@gnist/themes/tokens.css.js';
import { MyPageContractStep } from '../MyPageContract';
import {
    ContractFormModel,
    contractFormValidator,
    initialContractFormValues,
} from '../utils/contractFormUtils';
import { CustomerStep } from './CustomerStep';
import { PaymentStep } from './PaymentStep';
import i18next from 'i18next';
import { css, styled } from 'styled-components';
import { FormErrors } from './FormErrors';
import { useNavigate, useParams } from 'react-router';
import { useGetMyPageValuation } from '../../my-page/queries/useGetMyPageValuation';
import { MyPageValuationModel } from '@/types/MyPageValuationModel';
import { useUpdateMyPageContract } from '../../my-page/queries/useUpdateMyPageContract';
import { SigningStep } from './SigningStep';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { CarStep } from './CarStep';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import parse from 'html-react-parser';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { CustomerRole } from '@/utils/enums/CustomerRole';

interface ContractFormProps {
    readonly activeStep: MyPageContractStep;
    readonly steps: MyPageContractStep[];
    readonly setActiveStep: (step: MyPageContractStep) => void;
}

export const ButtonsWrapper = styled.div<{ $extraBottomPadding?: boolean }>`
    ${(props) => css`
        display: flex;
        align-self: flex-end;
        align-items: center;
        margin-top: ${tokens.spacing.xl};
        justify-content: flex-end;
        margin-bottom: ${props.$extraBottomPadding ? tokens.size['4xl'] : 0};

        button:first-of-type {
            margin-right: ${tokens.spacing.xl};
        }
    `}
`;

const NecessityText = styled.p`
    margin: -24px 0 var ${tokens.spacing.s};
`;

const ErrorMessage = styled(AlertBanner)`
    margin-top: ${tokens.spacing.l};

    a {
        color: ${tokens.color['on-background']};
        font-weight: 500;
    }
`;

export const MyPageContractForm = ({
    activeStep,
    steps,
    setActiveStep,
}: ContractFormProps) => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const updateMyPageContract = useUpdateMyPageContract(id, () =>
        setActiveStep(steps[steps.length - 1]),
    );
    const navigate = useNavigate();
    const { email } = usePurchaserEmail();
    const form = useLocalFormState<ContractFormModel>(
        initialContractFormValues(
            valuation?.customerRole === CustomerRole.BEHALF_OF_OWNER
                ? undefined
                : valuation?.customer,
        ),
        contractFormValidator,
        { showNecessityOn: 'required' },
    );

    const indexOfActiveStep = steps.indexOf(activeStep);
    const isFirstStep = activeStep === MyPageContractStep.CUSTOMER;
    const isCarStep = activeStep === MyPageContractStep.CAR;
    const isSecondLastStep = activeStep === MyPageContractStep.PAYMENT;
    const isLastStep = activeStep === MyPageContractStep.SIGNING;
    const signingPageUrl = valuation?.contract?.signingPageUrl;
    const showOpenContractButton = isLastStep && !!signingPageUrl;

    useEffect(() => {
        switch (activeStep) {
            case MyPageContractStep.CUSTOMER:
                datadogRum.addAction('Shows Customer contract form step');
                break;
            case MyPageContractStep.CAR:
                datadogRum.addAction('Shows Car contract form step');
                break;
            case MyPageContractStep.PAYMENT:
                datadogRum.addAction('Shows Payment contract form step');
                break;
            case MyPageContractStep.SIGNING:
                datadogRum.addAction('Shows Signing contract form step');
                break;
        }
    }, [activeStep]);

    const formContentByStep = (
        step: MyPageContractStep,
        valuation: MyPageValuationModel,
    ) => {
        switch (step) {
            case MyPageContractStep.CUSTOMER:
                return <CustomerStep />;
            case MyPageContractStep.CAR:
                return <CarStep />;
            case MyPageContractStep.PAYMENT:
                return (
                    <PaymentStep
                        contract={valuation.contract}
                        accountNumber={form.inputProps('accountNumber').value}
                    />
                );
            case MyPageContractStep.SIGNING:
                return <SigningStep />;
        }
    };

    return (
        <FormProvider
            id="contractForm"
            form={form}
            onSubmit={(data) =>
                updateMyPageContract.mutate({
                    ...data,
                    accountNumber: data.accountNumber.replace(/\s+/g, ''),
                })
            }
            hideNecessityText
        >
            {updateMyPageContract.isPending && (
                <LoadingOverlay delayMillis={0} fixed />
            )}
            {!(isLastStep || isCarStep) && (
                <NecessityText>{form.necessityText}</NecessityText>
            )}
            {valuation && formContentByStep(activeStep, valuation)}
            <FormErrors form={form} setActiveStep={setActiveStep} />
            <ButtonsWrapper $extraBottomPadding={isLastStep}>
                {!isLastStep && (
                    <TextButton
                        type="button"
                        onClick={() => {
                            if (steps.indexOf(activeStep) === 0)
                                return void navigate(-1);
                            else setActiveStep(steps[indexOfActiveStep - 1]);
                            if (updateMyPageContract.isError)
                                updateMyPageContract.reset();
                        }}
                    >
                        {i18next.t(
                            indexOfActiveStep === 0 ? 'cancel' : 'previous',
                        )}
                    </TextButton>
                )}
                {(isFirstStep || isCarStep) && (
                    <PrimaryButton
                        onClick={() =>
                            setActiveStep(steps[indexOfActiveStep + 1])
                        }
                    >
                        {i18next.t('next')}
                    </PrimaryButton>
                )}
                {isSecondLastStep && (
                    <PrimaryButton type="submit">
                        {i18next.t('saveData')}
                    </PrimaryButton>
                )}
                {showOpenContractButton && (
                    <PrimaryButton
                        onClick={() => (window.location.href = signingPageUrl)}
                    >
                        {i18next.t('openContract')}
                    </PrimaryButton>
                )}
            </ButtonsWrapper>
            {updateMyPageContract.isError && (
                <ErrorMessage
                    type="error"
                    message={
                        parse(
                            i18next.t('generalErrorMessage', {
                                email: email,
                            }),
                        ) as string
                    }
                />
            )}
        </FormProvider>
    );
};
