import { OfferModel, PriceOfferCustomerResponse } from '@/types/OfferModel';
import { Collapse } from '@/components/Collapse';
import { css, styled } from 'styled-components';
import {
    breakpointDesktop,
    GhostButton,
    Heading3Text,
    Icon,
    SecondaryButton,
} from '@gnist/design-system';
import i18next from 'i18next';
import { numberFormat } from '@/utils/numberFormat';
import { AttachmentCard } from './AttachmentCard';
import { useParams } from 'react-router';
import { useGetMyPageValuation } from '../queries/useGetMyPageValuation';
import { useState } from 'react';
import { useUpdatePriceOffer } from '../queries/useUpdatePriceOffer';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { NetPromoterScore } from '@/components/net-promoter-score/NetPromoterScore';
import moment from 'moment';
import { DATE_MONTH_FORMAT } from '@/utils/dateUtils';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';
import { DeclinationOfferResponseModal } from '@/views/my-page/components/DeclinationOfferResponseModal';
import { PriceBreakdown } from '@/components/PriceBreakdown';
import { tokens } from '@gnist/themes/tokens.css.js';
import { OfferResponse } from '@/views/my-page/components/OfferResponse';

const Label = styled.label`
    display: flex;
    text-transform: uppercase;
    font-size: ${tokens.typeface.size.s};
    font-weight: 500;
    color: ${tokens.color.primary};

    @media screen and (min-width: ${breakpointDesktop}) {
        font-size: ${tokens.typeface.size.s};
    }
`;

const PurchasePrice = styled.h2`
    font-size: ${tokens.typeface.size.xxl};
    font-weight: 500;
    margin: 0;
    width: fit-content;
    line-height: ${tokens.typeface['line-height']};
    color: ${tokens.color.primary};
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${tokens.color['surface-variant']};
    margin: 0 calc(${tokens.spacing.s} * -1);
    padding: ${tokens.spacing.s};
`;

const Toggle = styled.button<{ $isNotClickable: boolean }>`
    ${(props) => css`
        font-family: ${tokens.typeface.brand};
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        cursor: ${props.$isNotClickable ? 'auto' : 'pointer'};

        ${Icon.selector} {
            display: ${props.$isNotClickable ? 'none' : 'flex'};
            color: ${tokens.color.primary};
        }
    `}
`;

const Content = styled.div<{
    $isCollapsed: boolean;
}>`
    ${(props) => css`
        position: relative;
        overflow: hidden;
        height: ${props.$isCollapsed ? '0' : 'auto'};

        > div {
            padding: ${tokens.spacing.s} 0 0;
        }
    `}
`;

const StyledCollapse = styled(Collapse)`
    padding: 0;
`;

const Justification = styled.p`
    white-space: pre-wrap;
    padding-top: ${tokens.spacing.s};
`;

const ResponseSection = styled.div`
    padding-top: ${tokens.spacing.l};
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${tokens.spacing.s};
    padding-top: ${tokens.spacing.s};

    button {
        width: 100%;
    }
`;

const Attachments = styled.div`
    display: flex;
    width: 100%;
    row-gap: ${tokens.spacing.s};
    column-gap: ${tokens.spacing.s};
    padding-top: ${tokens.spacing.s};
    flex-wrap: wrap;
`;

const Response = styled(OfferResponse)`
    margin-top: ${tokens.spacing.s};
`;

const ResponseHeading = styled(Heading3Text)`
    font-size: ${tokens.typeface.size.m};
    padding-bottom: ${tokens.spacing.xxs};
`;

const Error = styled(GeneralErrorAlert)`
    margin: ${tokens.spacing.l} 0 0;
`;

interface PriceOfferProps {
    offer: OfferModel;
}

export const PriceOffer = ({ offer }: PriceOfferProps) => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const updatePriceOffer = useUpdatePriceOffer(id, offer.id);
    const { isError, isPending } = updatePriceOffer;
    const attachment = valuation?.offer.attachment;
    const deadlineDate = valuation?.offer.deadlineDate;
    const { showDeadlineDate } = useFeatureFlags();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isOpenDeclinationModal, setOpenDeclinationModal] = useState(false);
    const [showNPSBanner, setShowNPSBanner] = useState(false);

    return (
        <>
            <DeclinationOfferResponseModal
                close={() => setOpenDeclinationModal(false)}
                showNPSBanner={() => setShowNPSBanner(true)}
                isModalOpen={isOpenDeclinationModal}
                offerId={offer.id}
            />
            <div>
                {showNPSBanner && (
                    <NetPromoterScore
                        situation={
                            valuation?.showPriceBreakdown
                                ? 'OfferDeclinedShowPriceBreakdown'
                                : 'OfferDeclined'
                        }
                    />
                )}
                {isPending && <LoadingOverlay delayMillis={0} fixed />}
                <Header>
                    <Toggle
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        type="button"
                        $isNotClickable={!valuation?.showPriceBreakdown}
                    >
                        <div>
                            <Label>{i18next.t('weThinkWeCanOffer')}</Label>
                            <PurchasePrice>{`${numberFormat(
                                offer.purchasePrice,
                            )} ${i18next.t('priceSuffix')}`}</PurchasePrice>
                        </div>
                        <Icon
                            icon={isCollapsed ? 'expand_more' : 'expand_less'}
                        />
                    </Toggle>
                    <Content $isCollapsed={isCollapsed}>
                        {valuation?.showPriceBreakdown && (
                            <PriceBreakdown
                                calculation={valuation.calculation!}
                                purchasePrice={offer.purchasePrice}
                            />
                        )}
                    </Content>
                </Header>
                <StyledCollapse
                    id="offer-justification"
                    collapsedHeight="160"
                    backgroundColorToken={tokens.color.surface}
                    alignment="center"
                >
                    <Justification>{offer.justification}</Justification>
                </StyledCollapse>
                {attachment && (
                    <Attachments>
                        <AttachmentCard key={attachment.id} file={attachment} />
                    </Attachments>
                )}
                {valuation?.offer.customerResponse ? (
                    <Response
                        customerResponse={valuation?.offer.customerResponse}
                        offerId={offer.id}
                    />
                ) : (
                    <ResponseSection>
                        <ResponseHeading>
                            {i18next.t('offerResponseHeading')}
                        </ResponseHeading>
                        {showDeadlineDate && !!deadlineDate && (
                            <p>
                                {i18next.t('offerValidDeadline', {
                                    date: moment(deadlineDate).format(
                                        DATE_MONTH_FORMAT,
                                    ),
                                })}
                            </p>
                        )}
                        <Buttons>
                            <SecondaryButton
                                onClick={() => {
                                    updatePriceOffer.mutate({
                                        customerResponse:
                                            PriceOfferCustomerResponse.ACCEPTED,
                                    });
                                }}
                            >
                                {i18next.t('Accepted')}
                            </SecondaryButton>
                            <GhostButton
                                onClick={() => {
                                    setOpenDeclinationModal(true);
                                }}
                            >
                                {i18next.t('Declined')}
                            </GhostButton>
                        </Buttons>
                    </ResponseSection>
                )}
                {isError && <Error density="compact" />}
            </div>
        </>
    );
};
