import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MY_VALUATION_CONTRACT } from '@/http/endpoints';
import { put } from '@/http/put';
import { ContractFormModel } from '../../my-page-contract/utils/contractFormUtils';
import format from 'string-format';
import { usePhoneNumberCountryCode } from '@/utils/usePhoneNumberCountryCode';

export const useUpdateMyPageContract = (
    id: string | undefined,
    onSuccess: () => void,
) => {
    const updateMyPageContract = (contractForm: ContractFormModel) =>
        put<ContractFormModel, unknown>(
            format(MY_VALUATION_CONTRACT, id || ''),
            {
                firstName: contractForm.firstName,
                lastName: contractForm.lastName,
                phoneNumber: usePhoneNumberCountryCode(
                    contractForm.phoneNumber,
                ),
                email: contractForm.email,
                address: contractForm.address,
                zipCode: contractForm.zipCode,
                city: contractForm.city,
                accountNumber: contractForm.accountNumber,
            },
        ).request;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (contractForm: ContractFormModel) =>
            updateMyPageContract(contractForm),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['myPageValuation'],
            });
            onSuccess();
        },
    });
};
