import { Heading2Text } from '@gnist/design-system';
import i18next from 'i18next';
import parse from 'html-react-parser';
import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';

const Contact = styled.div`
    padding: ${tokens.size['4xl']} ${tokens.spacing.s} ${tokens.size['4xl']};

    ${Heading2Text.selector} {
        font-size: ${tokens.typeface.size.base};
    }

    a {
        color: ${tokens.color['on-background']};
    }
`;

export const ContactUs = () => {
    return (
        <Contact>
            <Heading2Text>{i18next.t('contactTitle')}</Heading2Text>
            <p>{parse(i18next.t('contactDescription'))}</p>
        </Contact>
    );
};
