import { ContractFormModel } from '../utils/contractFormUtils';
import { styled } from 'styled-components';
import { LocalFormState } from '@gnist/design-system/utilities/forms/useLocalFormState.js';
import i18next from 'i18next';
import { Icon, TextButton } from '@gnist/design-system';
import { formSteps as steps, MyPageContractStep } from '../MyPageContract';
import { tokens } from '@gnist/themes/tokens.css.js';

interface FormErrorsProps {
    readonly form: LocalFormState<ContractFormModel>;
    readonly setActiveStep: (step: MyPageContractStep) => void;
}

const ErrorWrapper = styled.div`
    margin-top: ${tokens.spacing.xl};
    padding: ${tokens.spacing.s};
    background-color: #ffe1e1;
    border: 1px solid #cd1f00;
    border-radius: 2px;
`;

const ErrorHeader = styled.h2`
    margin: 0 0 ${tokens.spacing.s};
    font-size: ${tokens.typeface.size.base};
    font-weight: 500;
    display: flex;
    align-items: center;
`;

export const ErrorHeaderIcon = styled(Icon)`
    color: ${tokens.color.error};
    padding-right: ${tokens.spacing.xxs};
`;

const ErrorItem = styled.div`
    justify-content: space-between;
    padding: 0;
    font-size: ${tokens.typeface.size.s};
    display: flex;
`;

const ErrorItemText = styled.li`
    justify-content: space-between;
    font-size: ${tokens.typeface.size.s};
    display: list-item;
`;

const ErrorSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${tokens.spacing.s} 0 ${tokens.spacing.xxs};

    h3 {
        font-size: ${tokens.typeface.size.s};
        font-weight: 500;
        margin: 0;
    }
`;

const ErrorButton = styled(TextButton)`
    margin-top: ${tokens.spacing.xxs};
    margin-left: auto;

    span {
        border-bottom-color: ${tokens.color['on-background']} !important;
    }
`;

export const FormErrors = ({ form, setActiveStep }: FormErrorsProps) => {
    const validatedFields = Object.keys(form.state?.validated);
    type ErrorField = { field: keyof ContractFormModel; value: boolean };
    const errors: ErrorField[] = Object.entries(form.state?.showError).map(
        ([field, value]): ErrorField => ({ field, value }) as ErrorField,
    );
    const activeErrors: (keyof ContractFormModel)[] = errors
        .filter((e) => e.value && !validatedFields.includes(e.field))
        .map((e) => e.field);
    const fieldValidityMessage = (field: keyof ContractFormModel): string =>
        form.inputProps(field)?.validity?.message || '';
    const stepOneErrors = activeErrors.filter(
        (e) =>
            e.includes('firstName') ||
            e.includes('lastName') ||
            e.includes('phoneNumber') ||
            e.includes('email') ||
            e.includes('address') ||
            e.includes('zipCode') ||
            e.includes('city'),
    );
    const stepTwoErrors = activeErrors.filter((e) =>
        e.includes('accountNumber'),
    );

    const stepLabel = (step: MyPageContractStep) => {
        switch (step) {
            case MyPageContractStep.CUSTOMER:
                return 'contractCustomerLabel';
            case MyPageContractStep.CAR:
                return 'contractCarLabel';
            case MyPageContractStep.PAYMENT:
                return 'contractPaymentLabel';
            case MyPageContractStep.SIGNING:
                return 'contractSigningLabel';
            default:
                return '';
        }
    };

    const errorMessagesByStep = (
        step: MyPageContractStep,
        stepErrors: (keyof ContractFormModel)[],
    ) => {
        return (
            stepErrors.length > 0 && (
                <>
                    <ErrorSection>
                        <h3>{i18next.t(stepLabel(step))}</h3>
                    </ErrorSection>
                    {stepErrors.map((field) => (
                        <ErrorItem key={field}>
                            <ul>
                                <ErrorItemText>
                                    <p>{`${i18next.t(
                                        field,
                                    )} - ${fieldValidityMessage(field)}`}</p>
                                </ErrorItemText>
                            </ul>
                        </ErrorItem>
                    ))}
                    <ErrorButton onClick={() => setActiveStep(step)} underline>
                        {`${i18next.t('goToStep')} "${i18next.t(
                            stepLabel(step),
                        )}"`}
                    </ErrorButton>
                </>
            )
        );
    };

    if (activeErrors.length) {
        return (
            <ErrorWrapper>
                <ErrorHeader>
                    <ErrorHeaderIcon icon="error" />
                    {i18next.t('contractFormErrorHeader')}
                </ErrorHeader>
                {errorMessagesByStep(steps[0], stepOneErrors)}
                {errorMessagesByStep(steps[1], stepTwoErrors)}
            </ErrorWrapper>
        );
    } else {
        return <></>;
    }
};
