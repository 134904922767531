import { NewCarBox } from '@/components/NewCarBox';
import { RejectionType } from '@/utils/enums/RejectionType';
import { NewCarBoxWrapper } from '../ValuationRequestView';
import i18next from 'i18next';
import { CustomInfoCard } from '@/views/valuation-request-view/components/CustomInfoCard';

interface RejectionPageContentProps {
    readonly rejectionType: RejectionType;
}

const rejectionReason = (rejectionType: RejectionType) => {
    switch (rejectionType) {
        case RejectionType.MODEL: {
            return i18next.t('rejectedModelDescription');
        }
        case RejectionType.AGE: {
            return i18next.t('rejectionDescription', {
                reason: i18next.t('rejectionReasonAge'),
            });
        }
        case RejectionType.MILEAGE: {
            return i18next.t('rejectionDescription', {
                reason: i18next.t('rejectionReasonMileage'),
            });
        }
        default: {
            return '';
        }
    }
};

export const RejectionPageContent = ({
    rejectionType,
}: RejectionPageContentProps) => {
    return (
        <>
            <CustomInfoCard
                title={i18next.t('rejectionTitle')}
                text={rejectionReason(rejectionType)}
                button={{
                    text: i18next.t('back'),
                    leadingIcon: 'arrow_backward',
                    href: 'https://mollerbil.no/selge-bil/vi-kjoper-bilen-din',
                }}
            />
            <NewCarBoxWrapper>
                <NewCarBox />
            </NewCarBoxWrapper>
        </>
    );
};
