import {
    useContext,
    createContext,
    PropsWithChildren,
    useCallback,
} from 'react';
import { get } from '@/http/get';
import { getCurrentEnvironment } from '@/http/utils/getCurrentEnvironment';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { useQuery } from '@tanstack/react-query';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_SESSION } from '@/http/endpoints';

export type AuthType = 'customer' | 'dealer';

interface AuthContextModel {
    readonly login: (authType: AuthType) => void;
    readonly logout: (authType: AuthType) => void;
    readonly loginAndConfirmValuation: (valuationId: number) => void;
}

const defaultAuthContext = {
    login: () => undefined,
    logout: () => undefined,
    loginAndConfirmValuation: () => undefined
};

export const AuthContext = createContext<AuthContextModel>(defaultAuthContext);

export const useAuth = () => useContext(AuthContext);

interface AuthResponse {
    readonly isAuthenticated: boolean;
    readonly claims: { name: ''; email: '' };
}

export const useGetUser = (authType: AuthType, enabled = true) => {
    const AUTH_GET_USER = `${AUTH_SESSION}?type=${authType}`;
    const getUserRequest = () =>
        get<AuthResponse>(AUTH_GET_USER).request.then((res) => res.data);

    return useQuery({
        queryKey: ['user'],
        queryFn: getUserRequest,
        enabled: enabled,
    });
};

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
    const { apiUrl } = getCurrentEnvironment();
    const currentUrl = window.location.href;
    const currentOrigin = window.location.origin;

    const login = useCallback(
        (authType: AuthType) => {
            window.location.href = `${apiUrl}/${AUTH_LOGIN}?returnUrl=${authType === 'customer'
                    ? `${currentOrigin}${PATH_MY_PAGE}`
                    : currentUrl
                }&type=${authType}`;
        },
        [apiUrl, currentOrigin, currentUrl]
    );

    const logout = (authType: AuthType) => {
        window.location.href = `${apiUrl}/${AUTH_LOGOUT}?type=${authType}`;
    };

    const loginAndConfirmValuation = (valuationId: number) => {
        window.location.href = `${apiUrl}/${AUTH_LOGIN}?returnUrl=${currentOrigin}${PATH_MY_PAGE}&type=customer&valuationId=${valuationId}`;
    }

    return (
        <AuthContext.Provider
            value={{
                login,
                logout,
                loginAndConfirmValuation
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
