import {
    TextButton,
    PrimaryButton,
    Icon,
    AllowedIcons,
} from '@gnist/design-system';
import i18next from 'i18next';
import { css, styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';

interface FormButtonsProps {
    primaryAction: {
        textKey: string;
        onClick?: () => void;
        icon?: AllowedIcons;
    };
    secondaryAction: {
        onClick: () => void;
        textKey: string;
    };
    extraMargin?: boolean;
}

const ButtonsWrapper = styled.div<{ $extraMargin: boolean }>`
    ${(props) => css`
        margin: ${props.$extraMargin ? tokens.spacing.xl : tokens.spacing.s} 0
            ${tokens.spacing.s};
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button:first-of-type {
            margin-right: ${tokens.spacing.xl};
        }
    `}
`;

export const FormButtons = ({
    primaryAction,
    secondaryAction,
    extraMargin = false,
}: FormButtonsProps) => {
    return (
        <ButtonsWrapper $extraMargin={extraMargin}>
            <TextButton onClick={() => secondaryAction.onClick()} type="button">
                {i18next.t(secondaryAction.textKey)}
            </TextButton>
            <PrimaryButton type="submit" onClick={primaryAction.onClick}>
                {i18next.t(primaryAction.textKey)}
                {primaryAction.icon && <Icon icon={primaryAction.icon} />}
            </PrimaryButton>
        </ButtonsWrapper>
    );
};