import { styled } from 'styled-components';
import { breakpointTablet } from '@gnist/design-system/utilities/layout';
import { EnvBadge } from '../EnvBadge';
import { IconButton } from '@gnist/design-system';
import Logo from '../../assets/moller-bil-logo.svg?react';
import i18next from 'i18next';
import { AuthType, useAuth, useGetUser } from '@/auth/AuthContext';
import { PATH_CONSENT } from '@/utils/url/routes';
import { tokens } from '@gnist/themes/tokens.css.js';

const HeaderElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 79px;
`;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    flex: 1;
    padding: 0 ${tokens.spacing.l};

    @media screen and (min-width: ${breakpointTablet}) {
        padding: 0 80px;
    }

    div {
        display: flex;
    }
`;

const LogoLink = styled.a`
    display: flex;
    align-self: baseline;
    text-decoration: none;

    svg {
        height: ${tokens.size.xl};
    }
`;

export const Header = ({ authType }: { authType: AuthType }) => {
    const { logout, login } = useAuth();
    const shouldFetchUser = !(window.location.pathname === PATH_CONSENT);
    const { data: user, isFetching } = useGetUser(authType, shouldFetchUser);

    return (
        <HeaderElement>
            <Nav>
                <div>
                    <LogoLink href="https://mollerbil.no/">
                        <Logo />
                    </LogoLink>
                    <EnvBadge />
                </div>
                {!isFetching && (
                    <div>
                        {user?.isAuthenticated ? (
                            <IconButton
                                onClick={() => logout(authType)}
                                icon="logout"
                                showLabel="left"
                                label={i18next.t('logout')}
                            />
                        ) : (
                            <IconButton
                                onClick={() => login(authType)}
                                icon="login"
                                showLabel="left"
                                label={i18next.t('login')}
                            />
                        )}
                    </div>
                )}
            </Nav>
        </HeaderElement>
    );
};
