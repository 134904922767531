import { styled } from 'styled-components';
import { InfoCard, IconButton, DetailText } from '@gnist/design-system';
import i18next from 'i18next';
import { tokens } from '@gnist/themes/tokens.css.js';

const Card = styled(InfoCard)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${tokens.spacing.xs} ${tokens.spacing.s};
    background-color: transparent;
    border-width: 1px;
    height: 40px;
    margin-top: ${tokens.spacing.xxs};
`;

interface ExternalLinkCardProps {
    readonly url: string;
    readonly label: string;
}

export const ExternalLinkCard = ({ url, label }: ExternalLinkCardProps) => {
    return (
        <Card>
            <DetailText style={{ fontSize: tokens.typeface.size.base }}>
                {i18next.t(label)}
            </DetailText>
            <IconButton
                icon="external_page"
                as="a"
                href={url}
                target="_blank"
                label={i18next.t(label)}
            />
        </Card>
    );
};
