import { OfferModel } from '@/types/OfferModel';
import i18next from 'i18next';
import { PriceOffer } from '@/views/my-page/components/PriceOffer';

interface PriceStepContentProps {
    offer: OfferModel;
}

export const PriceStepContent = ({ offer }: PriceStepContentProps) => {
    if (offer) {
        return <PriceOffer offer={offer} />;
    } else {
        return <p>{i18next.t('priceStepDescription')}</p>;
    }
};
