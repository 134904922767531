import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';
import { LeadText } from '@gnist/design-system';
import { ReactElement } from 'react';

const Card = styled.div`
    padding: ${tokens.spacing.s};
    display: flex;
    flex-direction: column;
    border-width: ${tokens.stroke.small};
    border-radius: ${tokens.radius.feedback};
    border-style: solid;
    gap: ${tokens.spacing.base};

    a {
        color: inherit;
    }
`;

const Title = styled(LeadText)`
    font-size: ${tokens.typeface.size.m};
    font-weight: ${tokens.typeface.weight.semibold};
`;

export type BannerType = 'success' | 'warning' | 'error' | 'info' | 'neutral';

export const Banner = ({
    type,
    heading,
    message,
    children,
}: {
    type: BannerType;
    heading: string;
    message?: string;
    children?: ReactElement;
}) => {
    const bannerTypeStyle = () => {
        switch (type) {
            case 'success':
                return {
                    backgroundColor: tokens.color['success-container'],
                    borderColor: tokens.color.success,
                };
            case 'error':
                return {
                    backgroundColor: tokens.color['error-container'],
                    borderColor: tokens.color.error,
                };
            case 'warning':
                return {
                    backgroundColor: tokens.color['warning-container'],
                    borderColor: tokens.color.warning,
                };
            case 'info':
                return {
                    backgroundColor: tokens.color['info-container'],
                    borderColor: tokens.color.info,
                };
            case 'neutral':
                return {
                    backgroundColor: tokens.color['surface-variant'],
                    borderColor: '#D2C5B4',
                };

            default:
                return {
                    backgroundColor: tokens.color['surface-variant'],
                    borderColor: '#D2C5B4',
                };
        }
    };

    return (
        <Card
            style={{
                backgroundColor: bannerTypeStyle().backgroundColor,
                borderColor: bannerTypeStyle().borderColor,
            }}
        >
            <Title>{heading}</Title>
            {message && <p>{message}</p>}
            {children}
        </Card>
    );
};
