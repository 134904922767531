import { GhostButton, breakpointTablet } from '@gnist/design-system';
import i18next from 'i18next';
import parse from 'html-react-parser';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { Banner, BannerType } from '@/components/Banner';
import { ReactElement } from 'react';
import { useUpdatePriceOffer } from '@/views/my-page/queries/useUpdatePriceOffer';
import { useParams } from 'react-router';
import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';

interface OfferResponseProps {
    customerResponse: PriceOfferCustomerResponse;
    offerId: string;
    className?: string;
}

const Button = styled(GhostButton)`
    margin-top: ${tokens.spacing.s};
    width: 100%;

    @media screen and (min-width: ${breakpointTablet}) {
        width: fit-content;
    }
`;

export const OfferResponse = ({
    customerResponse,
    offerId,
    className,
}: OfferResponseProps) => {
    const { email } = usePurchaserEmail();
    const { id } = useParams<{ id: string }>();
    const updatePriceOffer = useUpdatePriceOffer(id, offerId);
    const { isPending } = updatePriceOffer;

    const response = (): {
        type: BannerType;
        title: string;
        message: string;
        children?: ReactElement;
    } => {
        switch (customerResponse) {
            case PriceOfferCustomerResponse.ACCEPTED:
                return {
                    type: 'success',
                    title: 'offerAcceptedTitle',
                    message: 'offerAcceptedMessage',
                };
            case PriceOfferCustomerResponse.EXPIRED:
                return {
                    type: 'info',
                    title: 'offerExpiredTitle',
                    message: 'offerExpiredMessage',
                    children: (
                        <Button
                            onClick={() => {
                                updatePriceOffer.mutate({
                                    customerResponse:
                                        PriceOfferCustomerResponse.RENEWOFFER,
                                });
                            }}
                        >
                            {i18next.t('RenewOffer')}
                        </Button>
                    ),
                };
            case PriceOfferCustomerResponse.RENEWOFFER:
                return {
                    type: 'neutral',
                    title: 'offerRenewedTitle',
                    message: 'offerRenewedMessage',
                };
            case PriceOfferCustomerResponse.DECLINED:
            case PriceOfferCustomerResponse.DECLINED_PRICE_TOO_LOW:
            case PriceOfferCustomerResponse.DECLINED_VALUE_EXPLORATION:
            case PriceOfferCustomerResponse.DECLINED_SELLING_TO_SOMEONE_ELSE:
            case PriceOfferCustomerResponse.DECLINED_OTHER:
                return {
                    type: 'error',
                    title: 'offerDeclinedTitle',
                    message: 'offerDeclinedMessage',
                };
            default:
                return {
                    type: 'neutral',
                    title: '',
                    message: '',
                };
        }
    };

    return (
        <div className={className} style={{ position: 'relative' }}>
            {isPending && <LoadingOverlay delayMillis={0} />}
            <Banner
                type={response().type}
                heading={i18next.t(response().title)}
                message={
                    parse(
                        i18next.t(response().message, {
                            email: email,
                        }),
                    ) as string
                }
            >
                {response().children}
            </Banner>
        </div>
    );
};
