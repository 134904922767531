import { FormRadioGroup } from '../../my-page-contract/components/FormRadioGroup';
import {
    formInputs,
    FormProvider,
    useLocalFormState,
} from '@gnist/design-system';
import i18next from 'i18next';
import { styled } from 'styled-components';
import {
    initialSelfDeclarationElectricCarFormValues,
    initialSelfDeclarationElectricCarWithMileageFormValues,
    initialSelfDeclarationFormValues,
    initialSelfDeclarationWithMileageFormValues,
    SelfDeclarationElectricCarFormModel,
    selfDeclarationElectricCarFormValidator,
    SelfDeclarationElectricCarWithMileageFormModel,
    selfDeclarationElectricCarWithMileageFormValidator,
    SelfDeclarationFormModel,
    selfDeclarationFormValidator,
    SelfDeclarationWithMileageFormModel,
    selfDeclarationWithMileageFormValidator,
} from '../utils/selfDeclarationFormUtils';
import { ChargingCablesRadioGroup } from './ChargingCablesRadioGroup';
import { LocalFormState } from '@gnist/design-system/utilities/forms/useLocalFormState.js';
import { ReactElement, useEffect } from 'react';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';
import { tokens } from '@gnist/themes/tokens.css.js';
import { useGetUrlParams } from '@/views/valuation-request-view/utils/useGetUrlParams';

export type SelfDeclarationFormModels =
    | SelfDeclarationElectricCarWithMileageFormModel
    | SelfDeclarationWithMileageFormModel
    | SelfDeclarationElectricCarFormModel
    | SelfDeclarationFormModel;

const { TextArea, TextField } =
    formInputs<SelfDeclarationWithMileageFormModel>();

const TextAreaWrapper = styled.div`
    padding-top: ${tokens.spacing.xxs};
    width: 100%;

    > span {
        width: 100%;
    }
`;

const MileageTextField = styled(TextField)`
    margin-bottom: ${tokens.spacing.l};
`;

interface SelfDeclarationFormProps {
    readonly onSubmit: (data: SelfDeclarationFormModels) => void;
    readonly formBottomContent: ReactElement;
    readonly selfDeclaration?: SelfDeclarationModel;
    readonly isElectric: boolean;
    readonly hideNecessityText?: boolean;
    readonly mileage?: string;
    readonly setMileage?: (mileage: string) => void;
}

export const SelfDeclarationForm = ({
    onSubmit,
    formBottomContent,
    selfDeclaration,
    isElectric,
    hideNecessityText = false,
    mileage,
    setMileage,
}: SelfDeclarationFormProps) => {
    const { mileageFromUrl } = useGetUrlParams();

    const electricCarForm =
        useLocalFormState<SelfDeclarationElectricCarFormModel>(
            initialSelfDeclarationElectricCarFormValues(selfDeclaration),
            selfDeclarationElectricCarFormValidator,
        );
    const nonElectricCarForm = useLocalFormState<SelfDeclarationFormModel>(
        initialSelfDeclarationFormValues(selfDeclaration),
        selfDeclarationFormValidator,
    );
    const nonElectricCarWithMileageForm =
        useLocalFormState<SelfDeclarationWithMileageFormModel>(
            initialSelfDeclarationWithMileageFormValues(
                selfDeclaration,
                mileage,
            ),
            selfDeclarationWithMileageFormValidator,
        );
    const electricCarWithMileageForm =
        useLocalFormState<SelfDeclarationElectricCarWithMileageFormModel>(
            initialSelfDeclarationElectricCarWithMileageFormValues(
                selfDeclaration,
                mileage,
            ),
            selfDeclarationElectricCarWithMileageFormValidator,
        );

    const getForm = () => {
        if (!mileageFromUrl) {
            if (isElectric) {
                return electricCarWithMileageForm;
            } else return nonElectricCarWithMileageForm;
        } else if (isElectric) {
            return electricCarForm;
        } else return nonElectricCarForm;
    };

    const form: LocalFormState<SelfDeclarationFormModels> = getForm();

    useEffect(() => {
        const state = form.state.validated;
        if (state.hasBeenDamaged === false && !!state.damageInfo?.length) {
            form.inputProps('damageInfo').setValue('');
        }
        if (state.hasKnownFaults === false && !!state.knownFaultsInfo?.length) {
            form.inputProps('knownFaultsInfo').setValue('');
        }
    }, [form]);

    const handleSetMileage = () => {
        if (!mileageFromUrl) {
            const formObject = form as LocalFormState<
                | SelfDeclarationWithMileageFormModel
                | SelfDeclarationElectricCarWithMileageFormModel
            >;
            const mileageInputValue = formObject.state.validated.mileage;
            return (
                mileageInputValue && setMileage && setMileage(mileageInputValue)
            );
        }
    };

    return (
        <FormProvider
            id="selfDeclarationForm"
            form={form}
            onSubmit={(data) => {
                handleSetMileage();
                onSubmit(data);
            }}
            hideNecessityText={hideNecessityText}
        >
            {!mileageFromUrl && (
                <MileageTextField
                    field="mileage"
                    label={i18next.t('mileageLabel')}
                    suffix="km"
                    helperText={i18next.t('mileageHelpText')}
                />
            )}
            <FormRadioGroup form={form} field="hasTwoKeys" />
            <FormRadioGroup form={form} field="hasTwoTireSets" />
            {isElectric && (
                <ChargingCablesRadioGroup
                    form={form}
                    field="chargingCablesIncluded"
                />
            )}
            <FormRadioGroup form={form} field="isTrimmed" />
            <FormRadioGroup form={form} field="hasBeenDamaged" />
            {!!form.state.validated.hasBeenDamaged && (
                <TextAreaWrapper>
                    <TextArea
                        field="damageInfo"
                        label={i18next.t('damageInfo')}
                    />
                </TextAreaWrapper>
            )}
            <FormRadioGroup form={form} field="hasKnownFaults" />
            {!!form.state.validated.hasKnownFaults && (
                <TextAreaWrapper>
                    <TextArea
                        field="knownFaultsInfo"
                        label={i18next.t('knownFaultsInfo')}
                    />
                </TextAreaWrapper>
            )}
            {formBottomContent}
        </FormProvider>
    );
};
