import { useState } from 'react';
import { styled } from 'styled-components';
import { NpsScale } from './Scale';
import {
    Column,
    IconButton,
    PrimaryButton,
    TextArea,
} from '@gnist/design-system';
import { datadogRum } from '@datadog/browser-rum';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import i18next from 'i18next';
import { tokens } from '@gnist/themes/tokens.css.js';

const InputBox = styled.div`
    position: fixed;
    padding: ${tokens.spacing.m};
    min-height: 150px;
    margin-bottom: env(safe-area-inset-bottom);
    bottom: 0;
    left: 0;
    right: 0;
    background: ${tokens.color.background};
    animation-duration: 2s;
    animation-name: NPSInput-slide-in;
    z-index: 10000;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.16);

    @keyframes NPSInput-slide-in {
        from {
            bottom: -100%;
        }

        to {
            bottom: 0;
        }
    }
`;

const BoldText = styled.p`
    font-weight: 500;
`;

const InnerText = styled.div`
    width: 100%;
    max-width: 600px;
    min-height: 100px;
    margin: ${tokens.spacing.s} auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CloseButton = styled(IconButton)`
    float: right;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: ${tokens.spacing.s} 0 0 auto;
`;

const TextAreaLeft = styled(TextArea)`
    text-align: left;
`;

const NPS_DETRACTOR_THRESHOLD = 6;
type ContextSituation =
    | 'PriceEstimateAccepted'
    | 'PriceEstimateDeclined'
    | 'OfferDeclined'
    | 'OfferDeclinedShowPriceBreakdown'
    | 'ContractSigned'
    | 'ContractSignedAuction';

export const NetPromoterScore = ({
    situation,
}: {
    situation: ContextSituation;
}) => {
    const [dismissed, setDismissed] = useState(false);
    const [score, setScore] = useState<number | null>(null);
    const [feedback, setFeedback] = useState('');
    const [sent, setSent] = useState(false);
    const [animationParent] = useAutoAnimate();

    const npsQuestionBySituation = () => {
        switch (situation) {
            case 'ContractSigned':
            case 'ContractSignedAuction':
                return 'npsQuestionContractSigned';
            case 'OfferDeclined':
            case 'OfferDeclinedShowPriceBreakdown':
                return 'npsQuestionOfferDeclined';
            case 'PriceEstimateAccepted':
            case 'PriceEstimateDeclined':
                return 'npsQuestionPriceEstimate';
        }
    };

    const dismissNps = () => {
        if (!score) {
            datadogRum.addAction('User dismissed NPS modal', { situation });
        }
        setDismissed(true);
    };

    const submitScore = (value: number) => {
        setScore(value);
        datadogRum.addAction('User submitted NPS', { score: value, situation });
    };

    const submitFeedback = (value: string) => {
        setSent(true);
        if (feedback.length > 0) {
            datadogRum.addAction('User submitted text feedback in NPS', {
                feedback: value,
                situation,
                score,
            });
        }
    };

    if (dismissed) {
        return null;
    }

    return (
        <InputBox>
            <CloseButton
                icon="close"
                label={i18next.t('close')}
                onClick={() => dismissNps()}
            />
            <InnerText>
                {(score !== null && score > NPS_DETRACTOR_THRESHOLD) || sent ? (
                    <p>{i18next.t('thankYou')}</p>
                ) : (
                    <Column gap="xxs" ref={animationParent}>
                        <p>{i18next.t(npsQuestionBySituation())}</p>
                        <NpsScale submitScore={submitScore} score={score} />
                        {score !== null && (
                            <>
                                <BoldText>
                                    {i18next.t('weWishYourFeedback')}
                                </BoldText>
                                <TextAreaLeft
                                    label={i18next.t('yourFeedback')}
                                    helperText={i18next.t(
                                        'noPersonalInformation',
                                    )}
                                    onChange={(e) =>
                                        setFeedback(e.target.value)
                                    }
                                />
                                <ButtonWrapper>
                                    <PrimaryButton
                                        onClick={() => {
                                            submitFeedback(feedback);
                                        }}
                                    >
                                        {i18next.t(
                                            feedback.length === 0
                                                ? 'skip'
                                                : 'sendFeedback',
                                        )}
                                    </PrimaryButton>
                                </ButtonWrapper>
                            </>
                        )}
                    </Column>
                )}
            </InnerText>
        </InputBox>
    );
};
