import { styled } from 'styled-components';
import moment from 'moment';
import { DATE_TIME_PRETTY_FORMAT } from '@/utils/dateUtils';
import { IconButton } from '@gnist/design-system';
import i18next from 'i18next';
import { ImageModel } from '@/types/ImageModel';
import { tokens } from '@gnist/themes/tokens.css.js';

const Card = styled.div`
    display: flex;
    position: relative;
    overflow: hidden;
    min-height: 70px;
    max-height: 120px;
    border: 0.5px solid ${tokens.color.outline};
    border-radius: ${tokens.radius.card};
    background-color: ${tokens.color.surface};
    margin-bottom: ${tokens.spacing.xxs};

    button {
        display: flex;
        align-self: center;
        margin-right: ${tokens.spacing.s};

        svg path {
            fill: ${tokens.color.primary};
        }
    }
`;

const ImageWrapper = styled.div`
    width: 112px;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        min-height: 100%;
    }
`;

const Info = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    position: relative;
    flex-direction: column;
    margin: ${tokens.spacing.xs} ${tokens.spacing.s};

    h3 {
        font-size: ${tokens.typeface.size.base};
        font-weight: 400;
        margin: 0;
        line-height: ${tokens.typeface['line-height']};
        max-height: 280%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        -o-text-overflow: ellipsis;
        word-break: break-all;
    }
    p {
        font-size: ${tokens.typeface.size.s};
    }
`;

interface ImagePreviewProps {
    image: ImageModel;
    removeImage: (image: ImageModel) => void;
    isEditing: boolean;
}

export const ImagePreview = ({
    image,
    removeImage,
    isEditing,
}: ImagePreviewProps) => {
    return (
        <Card>
            <ImageWrapper>
                <img src={image.url || image.data} alt={image.name} />
            </ImageWrapper>
            <Info>
                <h3>{image.name}</h3>
                <p>
                    {moment(image.lastModifiedDate).format(
                        DATE_TIME_PRETTY_FORMAT,
                    )}
                </p>
            </Info>
            {isEditing && (
                <IconButton
                    icon="trash"
                    label={i18next.t('removeImage')}
                    onClick={() => removeImage(image)}
                />
            )}
        </Card>
    );
};
