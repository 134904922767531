import { CustomInfoCard } from '@/views/valuation-request-view/components/CustomInfoCard';
import i18next from 'i18next';
import { useGetUrlParams } from '@/views/valuation-request-view/utils/useGetUrlParams';

export const InvalidCar = () => {
    const { regNoFromUrl } = useGetUrlParams();

    return (
        <CustomInfoCard
            title={i18next.t('getCarErrorTitle')}
            text={i18next.t('getCarErrorMessage', {
                regNo: regNoFromUrl,
            })}
            button={{
                text: i18next.t('tryAgain'),
                leadingIcon: 'arrow_backward',
                href: 'https://mollerbil.no/selge-bil/vi-kjoper-bilen-din',
            }}
        />
    );
};
