import i18next from 'i18next';
import { styled } from 'styled-components';
import { CarModel } from '@/types/CarModel';
import { Heading3Text, InfoCard, Lozenge } from '@gnist/design-system';
import { tokens } from '@gnist/themes/tokens.css.js';

const Card = styled(InfoCard)`
    display: flex;
    flex-direction: column;
    row-gap: ${tokens.spacing.xxs};
    border: none;
    padding: ${tokens.spacing.s};
`;

const RegistrationNumber = styled(Lozenge)`
    width: fit-content;
`;

const Title = styled(Heading3Text)`
    color: ${tokens.color.primary};
    font-size: ${tokens.typeface.size.l};
`;

interface CarInfoProps {
    readonly car: CarModel;
}

export const CarInfo = ({ car }: CarInfoProps) => {
    return (
        <Card>
            <RegistrationNumber>{car.registrationNumber}</RegistrationNumber>
            <Title>
                {car.brand ? `${car.brand} ${car.model}` : i18next.t('unknown')}
            </Title>
        </Card>
    );
};
